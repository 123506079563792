import ComingSoon from "./ComingSoon";

export default function ReactionPage() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Reaction Page</h2>
      <ComingSoon />
    </div>
  );
}
