import React from 'react';
import AppsIcons from './AppsIcons';
import './AppPage.css';

export default function AppPage() {
  return (
    <>
      <h2 className="center-text">Apps and games</h2>
      <AppsIcons />
    </>
  );
}
