import ComingSoon from "./ComingSoon";

export default function RockClimbingPage() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Rock Climbing Page</h2>
      {/* You can add other content or components here */}
      <ComingSoon />
    </div>
  );
}
